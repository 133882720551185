<template>
  <div class="container-scroller">
    <Navbar />
    <div class="container-fluid page-body-wrapper">
      <Sidebar />
      <div class="main-panel">
        <div class="content-wrapper">
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div
                      class="modal"
                      tabindex="-1"
                      :style="
                        displayModal ? 'display: block;' : 'display: none;'
                      "
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title">Cargar archivo</h5>
                          </div>
                          <div class="modal-body">
                            <p>Seleccione el archivo</p>
                            <div class="input-group mb-3">
                              <input
                                type="file"
                                @change="uploadFile"
                                ref="file"
                                class="form-control"
                              />
                            </div>
                            <div
                              class="alert alert-danger"
                              role="alert"
                              v-if="error"
                            >
                              No se pudo cargar el archivo
                            </div>
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              data-bs-dismiss="modal"
                              @click="cerrarModalArchivo"
                            >
                              Cerrar
                            </button>
                            <button
                              type="button"
                              class="btn btn-primary"
                              @click="cargarArchivo"
                              :disabled="archivo ? false : true"
                            >
                              Procesar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <p class="card-title mb-0">Cliente Lead</p>
                    </div>
                    <div class="col-md-12 mb-2">

                      <button
                          @click="exportExcel"
                          class="btn btn-primary float-right"
                      >
                        <span class="icon-download"></span> Exportar Excel
                      </button>

                      <button style="margin-right: 10px"
                          v-if="rol !== 'ROLE_VISUALIZADOR'"
                          @click="abrirModalArchivo"
                          class="btn btn-secondary float-right"
                      >
                        <span class="icon-upload"></span> Cargar archivo
                      </button>

                    </div>

                  </div>
                  <br />
                  <div
                    style="height: 500px; overflow: scroll; overflow-x: auto"
                  >

                    <table class="table table-striped table-borderless">
                      <thead
                        class="table-dark"
                        style="position: sticky; top: 0"
                      >
                        <tr>
                          <th></th>
                          <th>Id Cliente Modelo</th>
                          <th>Tipo Identificación</th>
                          <th>Identificación</th>
                          <th>Nombres</th>
                          <th>Apellidos</th>
                          <th>Nombre Comercial</th>
                          <th>Teléfono Móvil 1</th>
                          <th>Teléfono Móvil 2</th>
                          <th>Teléfono Móvil 3</th>
                          <th>Dirección</th>
                          <th>Referencia de la Dirección</th>
                          <th>Barrio</th>
                          <th>Cod Provincia</th>
                          <th>Provincia</th>
                          <th>Cod Municipio</th>
                          <th>Municipio</th>
                          <th>Correo Electrónico</th>
                          <th>Fecha de Nacimiento</th>
                          <th>Estrato</th>
                          <th>Tipo Negocio</th>
                          <th>Subcanal</th>
                          <th>Subcanal Global</th>
                          <th>Latitud</th>
                          <th>Longitud</th>
                          <th>Cliente Cascaron</th>
                          <th>Cliente Existe/Bloqueado</th>
                          <th>Fecha Creación</th>
                          <th>Respuesta Encuesta</th>
                          <th>Cod Sector</th>
                          <th>BDR</th>
                          <th>Referido</th>
                          <th>Estado</th>
                          <th>Motivo</th>
                          <th>Codigo Cliente</th>
                          <th>Código Distrito</th>
                          <th>Distrito</th>
                          <th>Motivo</th>
                          <th>Foto Cédula</th>
                          <th>Nombres Apellidos</th>
                          <th>Número Base</th>
                          <th>Fecha nacimiento</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in listaClienteLead" :key="item.id">
                          <td>
                            <input
                              type="checkbox"
                              :value="item.id"
                              v-model="idSeleccionadosProxy"
                              @change="seleccionarFila($event)"
                              name="check"
                            />
                          </td>
                          <td>{{ item.id }}</td>
                          <td>{{ item.tipoIdentificacion }}</td>
                          <td>{{ item.identificacion }}</td>
                          <td>{{ item.nombres }}</td>
                          <td>{{ item.nombresApellidos }}</td>
                          <td>{{ item.nombreComercial }}</td>
                          <td>{{ item.numeroCelular }}</td>
                          <td>{{ item.numeroCelularAdicional }}</td>
                          <td></td>
                          <td>{{ item.direccion }}</td>
                          <td>{{ item.referencia }}</td>
                          <td>{{ item.barrio }}</td>
                          <td>{{ item.codigoProvincia }}</td>
                          <td>{{ item.provincia }}</td>
                          <td>{{ item.codigoMunicipio }}</td>
                          <td>{{ item.municipio }}</td>
                          <td>{{ item.correoElectronico }}</td>
                          <td></td>
                          <td>{{ item.estrato }}</td>
                          <td>{{ item.tipoNegocio }}</td>
                          <td>{{ item.subcanal }}</td>
                          <td></td>
                          <td>{{ item.latitud }}</td>
                          <td>{{ item.longitud }}</td>
                          <td>{{ item.clienteCascaron }}</td>
                          <td></td>
                          <td>{{ item.fecha }}</td>
                          <td></td>
                          <td>{{ item.codigoSector }}</td>
                          <td>{{ item.bdr }}</td>
                          <td>{{ item.referido }}</td>
                          <td></td>
                          <td></td>
                          <td>{{item.codigoCliente}}</td>
                          <td>{{item?.codigoDistrito}}</td>
                          <td>{{item?.distrito}}</td>
                          <td>{{item?.motivo}}</td>
                          <td>{{item?.fotoCedula}}</td>
                          <td>{{item?.nombresApellidos}}</td>
                          <td>{{item?.numeroBase}}</td>
                          <td>{{item?.clienteCascaron}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <hr />

                  <Modal :method="procesarSeleccionados" :proceso="metodo" />
                  <ModalRechazar
                    :method="rechazarSeleccionados"
                    :proceso="metodo"
                  />

                  <Alerta
                    v-if="mostrar"
                    titulo="Información."
                    mensaje="Proceso realizado exitosamente"
                  />
                  <div v-if="rol !== 'ROLE_VISUALIZADOR'">
                    <button
                      @click="aprobar"
                      class="btn btn-primary"
                      type="button"
                      data-toggle="modal"
                      data-target="#exampleModal"
                      :disabled="idSeleccionados?.length > 0 ? false : true"
                    >
                      <span class="icon-circle-check"></span> Aprobar Todos
                    </button>

                    <button
                      @click="rechazar"
                      class="btn btn-secondary btn-rechazar"
                      type="button "
                      data-toggle="modal"
                      data-target="#modalRechazar"
                      :disabled="idSeleccionados?.length > 0 ? false : true"
                    >
                      <span class="icon-circle-cross"></span>
                      Rechazar Todos
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { saveExcel } from "@progress/kendo-vue-excel-export";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Modal from "../../components/Modal";
import Alerta from "../../components/Alerta";
import ModalRechazar from "../../components/ModalRechazar.vue";

export default {
  components: { Modal, Navbar, Sidebar, Alerta, ModalRechazar },
  data() {
    return {
      idSeleccionadosProxy: [],
      idSeleccionados: [],
      metodo: null,
      mostrar: false,
      archivo: null,
      displayModal: false,
      error: false,
    };
  },
  created() {
    if (
      this.$store.state.auth.rol !== "ROLE_ADMIN" &&
      this.$store.state.auth.rol !== "ROLE_SADMIN" &&
      this.$store.state.auth.rol !== "ROLE_VISUALIZADOR"
    ) {
      this.$store.dispatch("auth/logout");
      return;
    }
  },
  methods: {
    exportExcel() {
      saveExcel({
        data: this.listaClienteLead,
        fileName: "data",
        columns: [
          { field: "id", title: "ID Cliente" },
          { field: "codigoCliente", title: "Codigo Cliente" },
          { field: "identificacion", title: "Identificación" },
          {
            field: "nombreComercial",
            title: "Nombre Comercial del Establecimiento",
          },
          { field: "direccion", title: "Dirección" },
          { field: "barrio", title: "Barrio" },
          { field: "referencia", title: "Referencia de la Dirección" },
          { field: "codigoMunicipio", title: "Código Municipio" },
          { field: "municipio", title: "Municipio" },
          { field: "codigoDistrito", title: "Código Distrito" },
          { field: "distrito", title: "Distrito" },
          { field: "codigoProvincia", title: "Código de Provincia" },
          { field: "provincia", title: "Provincia" },
          { field: "municipio", title: "Población" },
          { field: "latitud", title: "Latitud" },
          { field: "longitud", title: "Longitud" },
          { field: "numeroCelular", title: "Teléfono" },
          { field: "correoElectronico", title: "Correo electrónico" },
          { field: "tipoNegocio", title: "Tipo de Negocio" },
          { field: "subcanal", title: "Subcanal" },
          { field: "-", title: "Fecha de Nacimiento" },
          { field: "motivo", title: "Motivo" },
          { field: "fecha", title: "Fecha creación" },
          { field: "bdr", title: "Bdr" },
          { field: "fotoCedula", title: "Foto Cedula" },
          { field: "nombres", title: "Nombres" },
          { field: "nombresApellidos", title: "Apellidos" },
          { field: "numeroBase", title: "Número Base" },
          { field: "clienteCascaron", title: "Fecha de Nacimiento" },
        ],
      });
    },
    async consultarCLienteLeads() {
      try {
        this.loaderSpinner();
        await this.$store.dispatch("lead/consultarClienteLeads", "PENDIENTE");
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },

    // eslint-disable-next-line no-unused-vars
    seleccionarFila(e) {
      let checked = e?.target?.checked;
      let valor = e?.target?.value;
      if (checked) {
        this.idSeleccionados.push(parseInt(valor));
      } else {
        const filtrados = this.idSeleccionados.filter(
          (item) => item !== parseInt(valor)
        );
        this.idSeleccionados = filtrados;
      }
    },
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    aprobar() {
      this.metodo = "aprobar";
    },
    rechazar() {
      this.metodo = "rechazar";
    },

    async procesarSeleccionados() {
      await this.$store.dispatch("lead/procesarClienteLeads", {
        metodo: this.metodo,
        idClientes: this.idSeleccionados,
        clienteLeads: this.listaClienteLead,
      });
      this.mostrar = true;
      this.idSeleccionados = [];
    },
    async rechazarSeleccionados(motivo) {
      await this.$store.dispatch("lead/procesarClienteLeads", {
        metodo: this.metodo,
        idClientes: this.idSeleccionados,
        clienteLeads: this.listaClienteLead,
        motivo,
      });

      this.mostrar = true;
      this.idSeleccionados = [];
    },
    abrirModalArchivo() {
      this.displayModal = true;
      this.error = false;
    },
    cerrarModalArchivo() {
      this.displayModal = false;
    },
    uploadFile() {
      this.archivo = this?.$refs?.file?.files[0];
    },
    async cargarArchivo() {
      try {
        this.loaderSpinner();
        const formData = new FormData();
        formData.append("file", this.archivo);
        const resp = await this.$store.dispatch("lead/cargarArchivo", formData);
        if (resp === "error") {
          this.error = true;
          return;
        }
        this.loader.hide();
        this.displayModal = false;
        alert("Se ha cargado el archivo con éxito");
        this.consultarCLienteLeads();
      } catch (error) {
        this.error = true;
      } finally {
        this.loader.hide();
      }
    },
  },
  mounted() {
    this.consultarCLienteLeads();
  },

  computed: {
    listaClienteLead() {
      return this.$store.state.lead.clienteLeadsTotal.clienteLeads;
    },

    rol() {
      return this.$store.state.auth.rol;
    },
  },
};
</script>
<style>
.btn-rechazar {
  margin-left: 10px;
}
</style>
